import { BrowserRouter, NavLink, Link} from "react-router-dom";
import { AppContext } from "./libs/contextLib";
import {  LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavItem} from "react-bootstrap";
import styled from "styled-components";



import { useHistory } from "react-router-dom";
import Home from "./containers/Home";
import React, { useState , useEffect} from "react";
import Routes from "./Routes";
import "./App.css";
import { AwsConfigAuth } from "./config/auth";
import NewNav from './containers/NewNav/NewNav';





function App() {
  const history = useHistory()

  const [isAuthenticated, userHasAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [parts, setParts] = useState([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth > 395){setScreenWidth(true)}
      else{setScreenWidth(false)}
    };
  
    window.addEventListener('resize', handleResize);

    // Limpia el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAuthenticated]);

  const Nav = styled.nav`
  position: fixed;
  width: 100vw;
  box-shadow: 0 0 10px $clr-gray300;
  @media (min-width: 768px) {
  
  }

`;

const NavList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin: 0 2rem;
`;

const NavLogo = styled.li`
  list-style: none;
  margin-right: auto;
  cursor: pointer;

  svg {
    width: 2.5rem;
    transition: fill 250ms ease-in;

    &:hover,
    &:focus {
      fill: $clr-primary;
    }
  }
`;

const NavListItemDrop = styled.ul`
  display: ${props => (props.isOpen ? 'flex' : 'none')}; /* Show/hide based on state */
  flex-direction: column; /* Organiza los elementos en una columna */
  justify-content: space-around; /* Espacia uniformemente los elementos */
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  list-style: none;
  height: 10vh;
  right: 1; /* Alinea el menú desplegable con el borde derecho */
  transform: translateX(-50%); /* Ajusta el valor según sea necesario para moverlo a la izquierda */
  color:black;
  li {
    padding: 0.5rem 0;

    color:black;
  }

  a{
    color: black;
    &:hover {
      color: purple;
    }

  }
`;
const NavListItem = styled.li`
  list-style:none;

  position: relative;
  cursor: pointer;
`;
  const NavbarContainer = styled.nav`
  display: flex;
  width:${props => (props.screenWidth ? '100%' : '100%')};
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #333;
  color: white;

  a{
    color: ${props => (props.screenWidth ? 'white' : 'black')};
    justify-self:space-between;
    &:hover {
      color: #ADD8E6;
    }
  }
  .brand {
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
  }

  .links {
    display: flex;
    gap: 1rem;

 
    }
  }

  .toggle {
    display: none;
  }


`;



const handleDropdownClick = () => {
  setIsDropdownOpen(!isDropdownOpen);
};



async function onLoad() {
  try {
    // await Auth.currentSession();
    userHasAuthenticated(true);
  }
  catch(e) {
    if (e !== 'No current user') {
      alert(e);
    }
  }

  setIsAuthenticating(false);
}


async function handleLogout(event){
  event.preventDefault();

try {
  //  await Auth.signOut()
  //  .then(() => {
  //   console.log('Sign out complete')
  //   console.log('auth3')
  //  })
} catch (error) {
  console.log(error)
}
  userHasAuthenticated(false)
}
 
async function handleCreate(){
  history.push("/create-note")
}

return (

  <div className="App container">
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated , parts, setParts}} >


      <Routes />
    </AppContext.Provider>

  </div>
);
}


export default App;

