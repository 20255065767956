import React, { useEffect, useState } from 'react';
import { BodyComponent } from '@darshanpatel2608/human-body-react';
import { useAppContext } from '../libs/contextLib';

const withAdditionalLogic = (WrappedComponent) => {
  
  return (props) => {
    const { isAuthenticated, setParts, parts } = useAppContext();
    const [on, setOn] = useState(false); // Cambié la desestructuración por corchetes

    useEffect(() => {
      console.log('parts updated:', parts);
    }, [on]); 
    
    
    function cop(part) {
      
      if (parts.includes(part)) {
        setParts(parts.filter(item => item !== part));
      } else {
        setParts([...parts, part]);
      }

      
    }

    const handleClick = (event) => {
      // Additional logic before the original onClick functionality
      console.log(on);
      const eventName = event; // Adjusted to get the id of the event
      const allElements = document.querySelectorAll('*');
      let elementFound = false;

      if (on) {
        allElements.forEach(element => {
          if (element.matches(`path#${eventName}`)) {
            element.style.fill = 'red';
            elementFound = true;
          }
        });
      } else {
        allElements.forEach(element => {
          if (element.matches(`path#${eventName}`)) {
            element.style.fill = 'white';
            elementFound = true;
          }
        });
      }

      // Call the cop function after handling the click
      setOn(!on);
    };


    // Pass the new handleClick function to the wrapped component
    return <WrappedComponent {...props} onClick={handleClick} />;
  };
};

const ExtendedBody = withAdditionalLogic(BodyComponent);
export default ExtendedBody